import React, { useState } from "react";
import styled from "styled-components";
import PlayerCard from "../../../components/PlayerCard";
import PlayerModal from "../../../components/PlayerModal";
import { useRecoilValue } from "recoil";
import { dataState } from "../../../state/data";
import colors from "../../../assets/colors/colors";
import { fontSize } from "../../../assets/style/font";
import Field from "../../../assets/image/field.jpg";

const ManagerSquad = ({ squad, bench, captain }: any) => {
  const data = useRecoilValue(dataState);
  const [teamData, setTeamData] = useState<any>(null);
  const [playerData, setPlayerData] = useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const goalkeepers = squad.filter((player: any) => player.element_type === 1);
  const defenders = squad.filter((player: any) => player.element_type === 2);
  const midfielders = squad.filter((player: any) => player.element_type === 3);
  const forwards = squad.filter((player: any) => player.element_type === 4);
  console.log(captain);

  // add all event points
  const eventTotalPoints = squad.reduce((acc: number, player: any) => {
    return player.id === captain ? acc + 2 * player.event_points : acc + player.event_points;
  }, 0);

  const benchTotalPoints = bench.reduce((acc: number, player: any) => {
    return acc + player.event_points;
  }, 0);
  //   const eventTotalPoints = squad.map((player: any) => player.event_points);
  const handleOpenModal = () => {
    setIsModalOpen(true);
    let teamData: any;
    for (let i = 0; i < data.allTeamInfo.length; i++) {
      if (data.allTeamInfo[i].id === data.playerInfo[0].team) {
        teamData = data.allTeamInfo[i];
        break;
      }
    }
    setTeamData(teamData);
    setPlayerData(data.playerInfo[0]);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <FormationContainer>
        <Title>
          <strong> Total Points </strong>
          <span> {eventTotalPoints}</span>
        </Title>
        <Row>
          {goalkeepers.map((player: any) => (
            <PlayerCard
              key={player.id}
              player={player}
              isCaptain={player.id === captain}
              onClick={() => {
                handleOpenModal();
              }}
            />
          ))}
        </Row>
        <Row>
          {defenders.map((player: any) => (
            <PlayerCard
              key={player.id}
              player={player}
              isCaptain={player.id === captain}
              onClick={() => {
                handleOpenModal();
              }}
            />
          ))}
        </Row>
        <Row>
          {midfielders.map((player: any) => (
            <PlayerCard
              key={player.id}
              player={player}
              isCaptain={player.id === captain}
              onClick={() => {
                handleOpenModal();
              }}
            />
          ))}
        </Row>
        <Row>
          {forwards.map((player: any) => (
            <PlayerCard
              key={player.id}
              player={player}
              isCaptain={player.id === captain}
              onClick={() => {
                handleOpenModal();
              }}
            />
          ))}
        </Row>
        <BenchContainer>
          <BenchText>
            <strong>Bench Points</strong>
            <span> {benchTotalPoints}</span>
          </BenchText>
          <Row>
            {bench.map((player: any) => (
              <PlayerCard key={player.id} player={player} />
            ))}
          </Row>
        </BenchContainer>
      </FormationContainer>
      <ColorLegend>
        <LegendItem>
          <LegendColor color={colors.score0th} />
          <LegendText>Legendary (Event Points 15+)</LegendText>
        </LegendItem>
        <LegendItem>
          <LegendColor color={colors.score1st} />
          <LegendText>Top Performance (Event Points 10-14)</LegendText>
        </LegendItem>
        <LegendItem>
          <LegendColor color={colors.score2nd} />
          <LegendText>Decent Performance (Event Points 5-9)</LegendText>
        </LegendItem>
        <LegendItem>
          <LegendColor color={colors.score3rd} />
          <LegendText>Blank (Event Points 4 or less)</LegendText>
        </LegendItem>
      </ColorLegend>
    </>
  );
};

export default ManagerSquad;

const Title = styled.div`
  text-align: left;
  color: ${colors.textWhite};
  margin: 1rem 0;
  z-index: 1;
  font-size: ${fontSize.media_large.large};

  span {
    font-size: ${fontSize.media_large.large};
  }
`;

const FormationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 800px;
  margin: 1rem auto;
  position: relative;
  border-radius: 16px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden; /* 가상 요소가 넘치지 않도록 설정 */

  /* 내부 콘텐츠 스타일 */
  z-index: 1;

  @media (max-width: 768px) {
    padding: 0;
  }

  /* 가상 요소로 배경 이미지 처리 */
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${Field});
    background-size: cover;
    background-position: center;
    z-index: 0; /* 배경 이미지가 모든 콘텐츠 뒤에 위치하도록 설정 */
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem;

  & > * {
    margin: 0 5px;
  }
`;

const BenchContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.5rem;
`;

const BenchText = styled(Title)``;

// Color Legend Styles
const ColorLegend = styled.div`
  padding: 0.5rem;
  background-color: ${colors.cardBackground};
  border-radius: 8px;
  width: 80%;
  margin: 1rem auto 20px auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

const LegendColor = styled.div<{ color: string }>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  margin-right: 10px;
`;

const LegendText = styled.span`
  font-size: 0.9rem;
  color: ${colors.textBlack};
`;
