import axios from "axios";

export const getCommonData = async () => {
  const serverUrl = process.env.REACT_APP_SERVER_ADDRESS;
  const request = process.env.REACT_APP_SERVER_TYPE === "PROD" ? "https" : "http";

  const teamInfo = await axios.get(`${request}://${serverUrl}/api/team/all-team-info`);
  const allPlayerInfo: any = await axios.get(`${request}://${serverUrl}/api/player`);
  const currentRound = await axios.get(`${request}://${serverUrl}/api/common/current-round`);
  console.log(teamInfo.data);

  return { teamInfo: teamInfo.data, allPlayerInfo: allPlayerInfo.data, currentRound: currentRound.data };
};

export const getCurrentRound = async () => {
  const serverUrl = process.env.REACT_APP_SERVER_ADDRESS;
  const request = process.env.REACT_APP_SERVER_TYPE === "PROD" ? "https" : "http";

  const currentRound = await axios.get(`${request}://${serverUrl}/api/common/current-round`);

  return currentRound.data;
};
